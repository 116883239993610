import React, { useState, useEffect } from 'react'

import Page from '../templates/Page'
import Spinner from '../components/ui/Spinner'
import { useGetSettings } from '../utils/hooks'
import { colors, Button, Spacer, Input, Fieldset } from '../utils/styles'

const defaultForm = {
  infoMessageDe: '',
  infoMessageEn: '',
  showMessage: false,
}

const POST_URL =
  process.env.NODE_ENV === 'production'
    ? '/settings.php'
    : 'https://nighttec.de/settings.php'

const mode = process.env.NODE_ENV === 'production' ? 'same-origin' : 'no-cors'

const SettingsPage = () => {
  const [form, setForm] = useState(defaultForm)
  const [status, setStatus] = useState(null)

  const settigns = useGetSettings()

  useEffect(() => {
    setForm(settigns)
  }, [settigns])

  const handleForm = ({ target }) => {
    const { name, value } = target

    if (name === 'showMessage') {
      setForm({
        ...form,
        [name]: target.checked,
      })
      return
    }

    setForm({
      ...form,
      [name]: value,
    })
  }

  const submit = e => {
    e.preventDefault()
    const body = JSON.stringify(form)
    setStatus('loading')

    fetch(POST_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
      mode,
    }).then(res => {
      if (!res.ok) setStatus('error')
      setStatus('success')
      setTimeout(() => window?.location.reload(), 1000)
    })
  }

  const submitButtonContent = () => {
    if (status === 'loading') return <Spinner />
    if (status === 'success') return 'Success'
    if (status === 'error') return 'Error'
    return 'Submit'
  }

  const submitBackgroundColor =
    status === 'success'
      ? colors.androidGreen
      : status === 'error'
      ? colors.fireEngineRed
      : 'black'

  return (
    <Page title="Settings">
      <form
        onSubmit={submit}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Fieldset>
          <legend>
            <h3>Info banner</h3>
          </legend>
          <label htmlFor="info_message_de">
            Message german
            <br />
            <Input
              type="text"
              id="info_message_de"
              name="infoMessageDe"
              value={form.infoMessageDe}
              onChange={handleForm}
            />
          </label>
          <br />
          <br />
          <label htmlFor="info_message_en">
            Message english
            <br />
            <Input
              required
              type="text"
              id="info_message_en"
              name="infoMessageEn"
              value={form.infoMessageEn}
              onChange={handleForm}
            />
          </label>
          <br />
          <br />
          <label htmlFor="show_message">
            Visible
            <input
              type="checkbox"
              id="show_message"
              name="showMessage"
              checked={form.showMessage}
              onChange={handleForm}
            />
          </label>
        </Fieldset>
        <br />
        <br />
        <Button
          backgroundColor={submitBackgroundColor}
          type="submit"
          disabled={status === 'loading'}
          role="button"
        >
          {submitButtonContent()}
        </Button>
      </form>

      <Spacer height="5rem" />
    </Page>
  )
}

export default SettingsPage
