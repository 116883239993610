import React from 'react'

import './style.css'

const Spinner = () => {
  return (
    <div className="la-ball-beat">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner
